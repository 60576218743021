<template>
  <Container>
    <StoryBanner
      :image-source="imageSource"
      :image-alt="imageAlt"
      :text-position="left"
    >
      {{ text }}
    </StoryBanner>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import StoryBanner from "~/alchemy/elements/story_banner"

  export default {
    components: { StoryBanner },
    mixins: [AlchemyElement],
    computed: {
      imageSource() {
        return this.getValue("picture")
      },
      imageAlt() {
        return this.getIngredient("picture")?.altText
      },
      text() {
        return this.getValue("text")
      },
    },
  }
</script>
